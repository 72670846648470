import { Routes } from '@angular/router';


export const appRoutes = {
    login: "dashboard/Login",
    home: "Home",
    page:"Page",
    notFound: "dashboard/NotFound",
    studentHome:"StudentDashboard/Home",
}

export const routes: Routes = [
    {path:"",redirectTo:'Page',pathMatch:'full',},
    
     {path:"Home",loadComponent: () => import('./home/home.component').then(c=>c.HomeComponent)},
     {path:"Page",loadComponent: () => import('./page/page.component').then(c=>c.PageComponent)},
    
    // {path:"StudentsLogin",loadComponent: () => import('./auth/students-login/students-login.component').then(c=>c.StudentsLoginComponent),canActivate:[LoginGuard]},
    // {path:"TeachersLogin",loadComponent: () => import('./auth/teachers-login/teachers-login.component').then(c=>c.TeachersLoginComponent),canActivate:[LoginGuard]},
    
    
    // {path:"StudentDashboard",loadComponent: () => import('./student-dashboard/student-dashboard.component').then(c=>c.StudentDashboardComponent),canActivate:[LoginGuard],
    //     children:[
    //         {path:"",redirectTo:'Home',pathMatch:'full',},
    //         {path:"Home",loadComponent: () => import('./student-dashboard/student-home/student-home.component').then(c=>c.StudentHomeComponent),canActivate:[LoginGuard],},
    //         {path:"StudentInformation",loadComponent: () => import('./student-dashboard/student-information/student-information.component').then(c=>c.StudentInformationComponent),canActivate:[LoginGuard],},
    //         {path:"StudentExams",loadComponent: () => import('./student-dashboard/student-exams/student-exams.component').then(c=>c.StudentExamsComponent),canActivate:[LoginGuard],},
    //         {path:"StudentPayments",loadComponent: () => import('./student-dashboard/student-payments/student-payments.component').then(c=>c.StudentPaymentsComponent),canActivate:[LoginGuard],},
    //         {path:"StudentSettings",loadComponent: () => import('./student-dashboard/student-settings/student-settings.component').then(c=>c.StudentSettingsComponent),canActivate:[LoginGuard],}
    
    //     ]
    // },
    
    
    // {path:'access-denied',loadComponent: () => import('./auth/access-denid/access-denid.component').then(c=>c.AccessDenidComponent)},
    // {path:'not-found',loadComponent: () => import('./auth/not-found/not-found.component').then(c=>c.NotFoundComponent)},
    
    {path:"**",redirectTo:'not-found',pathMatch:'full',},
    ];
      
      